import { RouteReuseStrategy, DefaultUrlSerializer, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
// import { ReuseTabService } from './ReuseTabService';
import { ComponentRef } from '@angular/core';

export class CusRouteReuseStrategy implements RouteReuseStrategy {

    public static handlers: { [key: string]: DetachedRouteHandle } = {}

    public static closeTabUrl = '';

    // constructor(private reuseTabService: ReuseTabService) { }

    // Asks if a snapshot from the current routing can be used for the future routing.
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    // Asks if a snapshot for the current route already has been stored.
    // Return true, if handles map contains the right snapshot and the router should re-attach this snapshot to the routing.
    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (this.getKey(route) === 'login' || this.getKey(route) === 'home') {
            return false;
        }

        if (this.shouldResetReuseStrategy(route)) {
            this.deactivateAllHandles();
            return false;
        }

        if (this.shouldIgnoreReuseStrategy(route)) {
            return false;
        }

        return !!CusRouteReuseStrategy.handlers[this.getKey(route)];
    }

    // Load the snapshot from storage. It's only called, if the shouldAttach-method returned true.
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return CusRouteReuseStrategy.handlers[this.getKey(route)] || null;
    }

    // Asks if the snapshot should be detached from the router.
    // That means that the router will no longer handle this snapshot after it has been stored by calling the store-method.
    public shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return !this.shouldIgnoreReuseStrategy(route);
    }

    // After the router has asked by using the shouldDetach-method and it returned true, the store-method is called (not immediately but some time later).
    public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        if (!handle) {
            return;
        }
        if (CusRouteReuseStrategy.closeTabUrl === this.getKey(route)) {
            if (CusRouteReuseStrategy.handlers[this.getKey(route)]) {
                this.destroyComponent(CusRouteReuseStrategy.handlers[this.getKey(route)]);
                CusRouteReuseStrategy.handlers[this.getKey(route)] = null;
            }
            CusRouteReuseStrategy.closeTabUrl = '';
            return;
        }
        CusRouteReuseStrategy.handlers[this.getKey(route)] = handle;
    }

    private shouldResetReuseStrategy(route: ActivatedRouteSnapshot): boolean {
        let snapshot: ActivatedRouteSnapshot = route;

        while (snapshot.children && snapshot.children.length) {
            snapshot = snapshot.children[0];
        }

        return snapshot.data && snapshot.data.resetReuseStrategy;
    }

    private shouldIgnoreReuseStrategy(route: ActivatedRouteSnapshot): boolean {
        return route.data && route.data.defaultReuseStrategy;
    }

    public deactivateAllHandles(): void {
        let handers = CusRouteReuseStrategy.handlers;
        for (const key in handers) {
            this.destroyComponent(handers[key]);
        }
        CusRouteReuseStrategy.handlers = {};
    }

    private destroyComponent(handle: DetachedRouteHandle): void {
        const componentRef: ComponentRef<any> = handle['componentRef'];
        if (componentRef) {
            componentRef.destroy();
        }
    }

    private getKey(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map((snapshot: ActivatedRouteSnapshot) => snapshot.routeConfig ? snapshot.routeConfig.path : '')
            .filter((path: string) => path.length > 0)
            .join('');
    }
}