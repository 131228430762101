import {Injectable} from '@angular/core';

@Injectable()
export class SessionStorage {
    public sessionStorage: any;
    constructor() {
        if (!sessionStorage) {
            throw new Error('当前浏览器不支持，请更换浏览器！');
        }
        this.sessionStorage = sessionStorage;
    }

    public setItem(key: string, value: string): void {
        this.sessionStorage.setItem(key, value);
    }

    public getItem(key: string): string {
        return this.sessionStorage.getItem(key) || '';
    }

    public remove(key: string): any {
        this.sessionStorage.removeItem(key);
    }
}