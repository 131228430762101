import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './main/layout/layout.component';
import { AuthGuard } from './../utils/AuthGuard';
import { BtnAuthService } from './../components/btnAuth/btn-auth.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full', data: { aheadLoad: false } },
      { path: 'home', loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule) },

      // 系统管理
      { path: 'sys/user/user.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/user-list/user-list.module').then(m => m.UserListModule) },
      { path: 'sys/role/role.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'roleList', keepAlive: true }, loadChildren: () => import('./views/platform/system/role-list/role-list.module').then(m => m.RoleListModule) },
      { path: 'sys/agent/agentList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentList' }, loadChildren: () => import('./views/platform/system/agent-list/agent-list.module').then(m => m.AgentListModule) },
      { path: 'sys/shipper/shipperList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperList' }, loadChildren: () => import('./views/platform/system/shipper-list/shipper-list.module').then(m => m.ShipperListModule) },
      { path: 'sys/member/levelList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperList' }, loadChildren: () => import('./views/platform/system/member-level-list/level-list.module').then(m => m.ShipperListModule) },
      { path: 'sys/member/memberAgentList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperList' }, loadChildren: () => import('./views/platform/system/member-agent-list/level-list.module').then(m => m.ShipperListModule) },
      { path: 'sys/minigram/videoList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'videoList' }, loadChildren: () => import('./views/platform/system/minigram-video-list/video-list.module').then(m => m.ShipperListModule) },
      { path: 'sys/coupon/templateList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'templateList' }, loadChildren: () => import('./views/platform/system/coupon-template-list/template-list.module').then(m => m.ShipperListModule) },
      { path: 'sys/minigram/payList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'payList' }, loadChildren: () => import('./views/platform/system/minigram-pay-list/pay-list.module').then(m => m.ShipperListModule) },
      { path: 'sys/sjuser/userList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/sj_user_list/sjuser-list.module').then(m => m.ShipperListModule) },
      { path: 'sys/sjuser/editContent.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/edit-content/edit-content.module').then(m => m.EditContentModule) },
      { path: 'sys/sjuser/editUserArticle.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/edit-user-content/edit-content.module').then(m => m.EditContentModule) },
      { path: 'sys/truck/member/levelList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/truck_member_list/list.module').then(m => m.ShipperListModule) },
      { path: 'sys/sjUser/invoiceBill.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/invoice-bill/list.module').then(m => m.ShipperListModule) },
      { path: 'sys/truck/member/agentTrucksList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/agent_trucks_list/list.module').then(m => m.ShipperListModule) },
      { path: 'sys/truck/member/agentTrucksOpenDetail.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/agent_trucks_open_detail/list.module').then(m => m.ShipperListModule) },
      { path: 'sys/truck/member/agentAllTrucksDetail.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/agent_all_trucks_detail/list.module').then(m => m.ShipperListModule) },
      { path: 'sys/truck/articleIntegral.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/setting/config.module').then(m => m.ConfigModule) },
      { path: 'sys/sjuser/userGrowUpSetting.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'userList' }, loadChildren: () => import('./views/platform/system/user-grow-up-setting/config.module').then(m => m.ConfigModule) },

      { path: 'sys/diction/diction.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'dictionList' }, loadChildren: () => import('./views/platform/system/diction/diction.module').then(m => m.DictionModule) },
      { path: 'sys/fdd/fadada.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'fadadaList' }, loadChildren: () => import('./views/platform/system/fadada/fadada.module').then(m => m.FadadaModule) },
      { path: 'sys/config/config.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'config' }, loadChildren: () => import('./views/platform/system/config/config.module').then(m => m.ConfigModule) },
      { path: 'sys/menuGroup/menuGroup.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'menugroupList' }, loadChildren: () => import('./views/platform/system/menu-group/menu-group.module').then(m => m.MenuGroupModule) },
      { path: 'sys/msg/msg.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'msgList' }, loadChildren: () => import('./views/platform/system/msg-list/msg-list.module').then(m => m.MsgListModule) },
      { path: 'sys/fdd5/fadada5.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'fadada5List' }, loadChildren: () => import('./views/platform/system/fadada5/fadada5.module').then(m => m.Fadada5Module) },
      { path: 'sys/appDriver/appDriver.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'appDriver' }, loadChildren: () => import('./views/platform/system/app-driver/app-driver.module').then(m => m.AppDriverModule) },
      { path: 'sys/appShipper/appShipper.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'appShipper' }, loadChildren: () => import('./views/platform/system/app-shipper/app-shipper.module').then(m => m.AppShipperModule) },
      { path: 'sys/shipper/shipperListPerson.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperListPerson' }, loadChildren: () => import('./views/platform/system/shipper-list-person/shipper-list-person.module').then(m => m.ShipperListPersonModule) },
      { path: 'sys/cancelBind/cancelBind.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'cancelBind' }, loadChildren: () => import('./views/platform/system/cancel-bind/cancel-bind.module').then(m => m.CancelBindModule) },
      { path: 'sys/tmpUpload/tmpUpload.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'tmpUpload' }, loadChildren: () => import('./views/platform/system/tmp-upload/tmp-upload.module').then(m => m.TmpUploadModule) },

      // 基础信息管理
      { path: 'baseData/consigner/consigner.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'consigner' }, loadChildren: () => import('./views/platform/baseData/consigner/consigner.module').then(m => m.ConsignerModule) },
      { path: 'baseData/goods/goods.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'goods' }, loadChildren: () => import('./views/platform/baseData/goods/goods.module').then(m => m.GoodsModule) },
      { path: 'baseData/shipper/shipper.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipper' }, loadChildren: () => import('./views/platform/baseData/shipper/shipper.module').then(m => m.ShipperModule) },
      { path: 'baseData/insurance/insurance.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'insurance' }, loadChildren: () => import('./views/platform/baseData/insurance/insurance.module').then(m => m.InsuranceModule) },
      { path: 'baseData/app-version/app-version.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'app-version'}, loadChildren: () => import('./views/platform/baseData/app-version/app-version.module').then(m => m.AppVersionModule) },

      // 客服调度
      { path: 'cus/cusSvr/cusSvrList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'cusSvrList' }, loadChildren: () => import('./views/platform/cusSvrDis/cus-svr-list/cus-svr-list.module').then(m => m.CusSvrListModule) },
      { path: 'cus/cusSvr/scheduleList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'scheduleList' }, loadChildren: () => import('./views/platform/cusSvrDis/schedule-plan/schedule-plan.module').then(m => m.SchedulePlanModule) },

      // 运力管理
      { path: 'carryManager/driver/driver.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'driver' }, loadChildren: () => import('./views/platform/carryManager/driver/driver.module').then(m => m.DriverModule) },
      { path: 'carryManager/team/team.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'team' }, loadChildren: () => import('./views/platform/carryManager/team/team.module').then(m => m.TeamModule) },
      { path: 'carryManager/truck/truck.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'truck' }, loadChildren: () => import('./views/platform/carryManager/truck/truck.module').then(m => m.TruckModule) },
      { path: 'carryManager/errorCarryCheck/errorCarryCheck.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'errorCarryCheck' }, loadChildren: () => import('./views/platform/carryManager/error-carry-check/error-carry-check.module').then(m => m.ErrorCarryCheckModule) },
      { path: 'carryManager/broker/broker.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'broker' }, loadChildren: () => import('./views/platform/carryManager/broker/broker.module').then(m => m.BrokerModule) },
      { path: 'carryManager/agentor/agentor.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentor' }, loadChildren: () => import('./views/platform/carryManager/agentor/agentor.module').then(m => m.AgentorModule) },
      { path: 'carryManager/check/cardCheck.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentor' }, loadChildren: () => import('./views/platform/carryManager/check/card-check.module').then(m => m.CardCheckModule) },

      // 业务中心
      { path: 'business/order/order.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'order' }, loadChildren: () => import('./views/platform/business/order/order.module').then(m => m.OrderModule) },
      { path: 'business/publish/publish.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'publish' }, loadChildren: () => import('./views/platform/business/publish/publish.module').then(m => m.PublishModule) },
      { path: 'business/waybill/waybill.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'waybill' }, loadChildren: () => import('./views/platform/business/waybill/waybill.module').then(m => m.WaybillModule) },
      { path: 'business/bulkOrder/bulkOrder.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'bulkOrder' }, loadChildren: () => import('./views/platform/business/bulk-order/bulk-order.module').then(m => m.BulkOrderModule) },
      { path: 'business/errorWaybillCheck/errorWaybillCheck.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'errorWaybillCheck' }, loadChildren: () => import('./views/platform/business/error-waybill-check/error-waybill-check.module').then(m => m.ErrorWaybillCheckModule) },
      { path: 'business/riskWaybill/riskWaybill.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'riskWaybill' }, loadChildren: () => import('./views/platform/business/risk-waybill/risk-waybill.module').then(m => m.RiskWaybillModule) },//风险运单管理
      { path: 'business/appear/appear.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'appear' }, loadChildren: () => import('./views/platform/business/appear/appear.module').then(m => m.AppearModule) },
      { path: 'business/updateWaybill/updateWaybill.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'updateWaybill' }, loadChildren: () => import('./views/platform/business/update-waybill/update-waybill.module').then(m => m.UpdateWaybillModule) },//运单纠正功能


      // 财务中心
      { path: 'financial/checkMoney/checkMoney.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'checkMoney' }, loadChildren: () => import('./views/platform/financial/check-money/check-money.module').then(m => m.CheckMoneyModule) },
      { path: 'financial/payMoney/payMoney.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'payMoney' }, loadChildren: () => import('./views/platform/financial/pay-money/pay-money.module').then(m => m.PayMoneyModule) },
      { path: 'financial/payMoneyDetail/payMoneyDetail.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'payMoneyDetail' }, loadChildren: () => import('./views/platform/financial/pay-money-detail/pay-money-detail.module').then(m => m.PayMoneyDetailModule) },
      { path: 'financial/shipperAdvance/shipperAdvance.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperAdvance' }, loadChildren: () => import('./views/platform/financial/shipper-advance/shipper-advance.module').then(m => m.ShipperAdvanceModule) },
      { path: 'financial/statement/statement.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'statement' }, loadChildren: () => import('./views/platform/financial/statement/statement.module').then(m => m.StatementModule) },

      // 发票管理
      { path: 'invoice/outInvoice/outInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'outInvoice' }, loadChildren: () => import('./views/platform/invoice/out-invoice/out-invoice.module').then(m => m.OutInvoiceModule) },
      { path: 'invoice/shipperApplyInvoice/shipperInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperInvoice' }, loadChildren: () => import('./views/platform/invoice/shipper-invoice/shipper-invoice.module').then(m => m.ShipperInvoiceModule) },
      { path: 'invoice/checkApplyInvoice/checkInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'checkInvoice' }, loadChildren: () => import('./views/platform/invoice/check-invoice/check-invoice.module').then(m => m.CheckInvoiceModule) },
      { path: 'invoice/agentInvoice/agentInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentInvoice' }, loadChildren: () => import('./views/platform/invoice/agent-invoice/agent-invoice.module').then(m => m.AgentInvoiceModule) },
      { path: 'invoice/applyTaxInvoice/taxInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'taxInvoice' }, loadChildren: () => import('./views/platform/invoice/tax-invoice/tax-invoice.module').then(m => m.TaxInvoiceModule) },
      { path: 'invoice/waybill/taxStatement.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'taxStatement' }, loadChildren: () => import('./views/platform/invoice/statement/tax-statement.module').then(m => m.TaxStatementModule) },

      // 风险管控
      { path: 'riskControll/riskDic/riskDic.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'riskDic' }, loadChildren: () => import('./views/platform/riskControll/risk-dic/risk-dic.module').then(m => m.RiskDicModule) },
      { path: 'riskControll/riskItem/riskItem.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'riskItem' }, loadChildren: () => import('./views/platform/riskControll/risk-item/risk-item.module').then(m => m.RiskItemModule) },
      { path: 'riskControll/riskData/riskData.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'riskData' }, loadChildren: () => import('./views/platform/riskControll/risk-data/risk-data.module').then(m => m.RiskDataModule) },

      // 异常管理
      { path: 'errorControl/waybillFee/waybillFee.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'waybillFee' }, loadChildren: () => import('./views/platform/errorControl/waybill-fee-error/waybill-fee-error.module').then(m => m.WaybillFeeErrorModule) },
      { path: 'errorControl/waybillTime/waybillTime.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'waybillTime' }, loadChildren: () => import('./views/platform/errorControl/waybill-time-error/waybill-time-error.module').then(m => m.WaybillTimeErrorModule) },
      { path: 'errorControl/waybillTrack/WaybillTrack.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'WaybillTrack' }, loadChildren: () => import('./views/platform/errorControl/waybill-track-error/waybill-track-error.module').then(m => m.WaybillTrackErrorModule) },

      // 数据上报
      { path: 'dataReport/waybillReport/waybillReport.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'waybillReport' }, loadChildren: () => import('./views/platform/dataReport/waybill-report/waybill-report.module').then(m => m.WaybillReportModule) },
      { path: 'dataReport/driverReport/driverReport.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'driverReport' }, loadChildren: () => import('./views/platform/dataReport/driver-report/driver-report.module').then(m => m.DriverReportModule) },
      { path: 'dataReport/truckReport/truckReport.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'truckReport' }, loadChildren: () => import('./views/platform/dataReport/truck-report/truck-report.module').then(m => m.TruckReportModule) },

      // 消息设置
      { path: 'news/msg/msg.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'msg' }, loadChildren: () => import('./views/platform/news/msg/msg.module').then(m => m.MsgModule) },
      { path: 'news/template/template.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'template' }, loadChildren: () => import('./views/platform/news/template/template.module').then(m => m.TemplateModule) },
      { path: 'news/record/record.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'record' }, loadChildren: () => import('./views/platform/news/record/record.module').then(m => m.RecordModule) },
      { path: 'news/newsDiction/newsDiction.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'newsDiction' }, loadChildren: () => import('./views/platform/news/news-diction/news-diction.module').then(m => m.NewsDictionModule) },

      // 日志管理
      { path: 'log/logRecord/logRecord.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'logRecord' }, loadChildren: () => import('./views/platform/log/log-record/log-record.module').then(m => m.LogRecordModule) },
      { path: 'log/realTimeLog/realTimeLog.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'realTimeLog' }, loadChildren: () => import('./views/platform/log/real-time-log/real-time-log.module').then(m => m.RealTimeLogModule) },

      // 数据分析
      { path: 'dataAnaly/waybillAnaly/waybillAnaly.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'waybillAnaly' }, loadChildren: () => import('./views/platform/dataAnaly/waybill-analy/waybill-analy.module').then(m => m.WaybillAnalyModule) },
      { path: 'dataAnaly/orderAnaly/orderAnaly.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'orderAnaly' }, loadChildren: () => import('./views/platform/dataAnaly/order-analy/order-analy.module').then(m => m.OrderAnalyModule) },

      // 综合运单
      // 综合运单
      // 报表统计

      // =======================================================代理商部分==================================================== //
      // 基础信息管理
      { path: 'agent/baseData/shipper/shipper.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentShipperList' }, loadChildren: () => import('./views/agent/baseData/agent-shipper/agent-shipper.module').then(m => m.AgentShipperModule) },
      { path: 'agent/baseData/goods/goods.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentGoodsList' }, loadChildren: () => import('./views/agent/baseData/agent-goods/agent-goods.module').then(m => m.AgentGoodsModule) },
      { path: 'agent/baseData/consigner/consigner.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentConsignerList' }, loadChildren: () => import('./views/agent/baseData/agent-consigner/agent-consigner.module').then(m => m.AgentConsignerModule) },

      // 运力管理
      { path: 'agent/carryManager/driver/driver.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentDriverList' }, loadChildren: () => import('./views/agent/carryManager/agent-driver/agent-driver.module').then(m => m.AgentDriverModule) },
      { path: 'agent/carryManager/team/team.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentTeamList' }, loadChildren: () => import('./views/agent/carryManager/agent-team/agent-team.module').then(m => m.AgentTeamModule) },
      { path: 'agent/carryManager/truck/truck.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentTruckList' }, loadChildren: () => import('./views/agent/carryManager/agent-truck/agent-truck.module').then(m => m.AgentTruckModule) },
      { path: 'agent/carryManager/broker/broker.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentBroker' }, loadChildren: () => import('./views/agent/carryManager/agent-broker/agent-broker.module').then(m => m.AgentBrokerModule) },
      { path: 'agent/carryManager/agentor/agentor.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentAgentor' }, loadChildren: () => import('./views/agent/carryManager/agent-agentor/agent-agentor.module').then(m => m.AgentAgentorModule) },

      //代理商管理
      { path: 'agent/carryManager/sjtruck/truck.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentTruckList' }, loadChildren: () => import('./views/agent/carryManager/sjagent-truck/agent-truck.module').then(m => m.AgentTruckModule) },

      // 业务中心
      { path: 'agent/business/order/order.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentOrderList' }, loadChildren: () => import('./views/agent/business/agent-order/agent-order.module').then(m => m.AgentOrderModule) },
      { path: 'agent/business/publish/publish.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentPublishList' }, loadChildren: () => import('./views/agent/business/agent-publish/agent-publish.module').then(m => m.AgentPublishModule) },
      { path: 'agent/business/waybill/waybill.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentWaybillList' }, loadChildren: () => import('./views/agent/business/agent-waybill/agent-waybill.module').then(m => m.AgentWaybillModule) },
      { path: 'agent/business/bulkOrder/bulkOrder.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentBulkOrderList' }, loadChildren: () => import('./views/agent/business/agent-bulk-order/agent-bulk-order.module').then(m => m.AgentBulkOrderModule) },
      { path: 'agent/business/updateWaybill/updateWaybill.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentUpdateWaybill' }, loadChildren: () => import('./views/agent/business/agent-update-waybill/agent-update-waybill.module').then(m => m.AgentUpdateWaybillModule) },//运单纠正功能

      // 系统管理
      { path: 'agent/sys/user/user.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentUserList' }, loadChildren: () => import('./views/agent/system/agent-user-list/agent-user-list.module').then(m => m.AgentUserListModule) },
      { path: 'agent/sys/role/role.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentRoleList' }, loadChildren: () => import('./views/agent/system/agent-role-list/agent-role-list.module').then(m => m.AgentRoleListModule) },
      { path: 'agent/sys/shipper/shipperList.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperList' }, loadChildren: () => import('./views/agent/system/agent-shipper-list/agent-shipper-list.module').then(m => m.AgentShipperListModule) },
      { path: 'agent/sys/appShipper/appShipper.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentAppShipper' }, loadChildren: () => import('./views/agent/system/agent-app-shipper/agent-app-shipper.module').then(m => m.AgentAppShipperModule) },
      { path: 'agent/sys/appDriver/appDriver.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentAppDriver' }, loadChildren: () => import('./views/agent/system/agent-app-driver/agent-app-driver.module').then(m => m.AgentAppDriverModule) },
      { path: 'agent/sys/shipper/shipperListPerson.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentShipperListPerson' }, loadChildren: () => import('./views/agent/system/agent-shipper-list-person/agent-shipper-list-person.module').then(m => m.AgentShipperListPersonModule) },

      // 财务中心
      { path: 'agent/financial/checkMoney/checkMoney.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentCheckMoney' }, loadChildren: () => import('./views/agent/financial/agent-check-money/agent-check-money.module').then(m => m.AgentCheckMoneyModule) },
      { path: 'agent/financial/payMoney/payMoney.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentPayMoney' }, loadChildren: () => import('./views/agent/financial/agent-pay-money/agent-pay-money.module').then(m => m.AgentPayMoneyModule) },
      { path: 'agent/financial/payMoneyDetail/payMoneyDetail.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentPayMoneyDetail' }, loadChildren: () => import('./views/agent/financial/agent-pay-money-detail/agent-pay-money-detail.module').then(m => m.AgentPayMoneyDetailModule) },
      { path: 'agent/financial/shipperAdvance/shipperAdvance.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentShipperAdvance' }, loadChildren: () => import('./views/agent/financial/agent-shipper-advance/agent-shipper-advance.module').then(m => m.AgentShipperAdvanceModule) },
      { path: 'agent/financial/statement/statement.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentStatement' }, loadChildren: () => import('./views/agent/financial/agent-statement/agent-statement.module').then(m => m.AgentStatementModule) },
      
      // 发票管理
      { path: 'agent/invoice/shipperApplyInvoice/shipperInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentShipperInvoice' }, loadChildren: () => import('./views/agent/invoice/agent-shipper-invoice/agent-shipper-invoice.module').then(m => m.AgentShipperInvoiceModule) },
      { path: 'agent/invoice/checkApplyInvoice/checkInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentCheckInvoice' }, loadChildren: () => import('./views/agent/invoice/agent-check-invoice/agent-check-invoice.module').then(m => m.AgentCheckInvoiceModule) },
      { path: 'agent/invoice/applyTaxInvoice/taxInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentTaxInvoice' }, loadChildren: () => import('./views/agent/invoice/agent-tax-invoice/agent-tax-invoice.module').then(m => m.AgentTaxInvoiceModule) },
      { path: 'agent/invoice/waybill/taxStatement.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'agentTaxStatement' }, loadChildren: () => import('./views/agent/invoice/agent-statement/agent-tax-statement.module').then(m => m.AgentTaxStatementModule) },

      // =======================================================货主部分====================================================== //
      // 基础信息管理
      { path: 'shipper/baseData/shipper/shipper.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperShipperList' }, loadChildren: () => import('./views/shipper/baseData/shipper-shipper/shipper-shipper.module').then(m => m.ShipperShipperModule) },
      { path: 'shipper/baseData/goods/goods.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperGoodsList' }, loadChildren: () => import('./views/shipper/baseData/shipper-goods/shipper-goods.module').then(m => m.ShipperGoodsModule) },
      { path: 'shipper/baseData/consigner/consigner.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperConsignerList' }, loadChildren: () => import('./views/shipper/baseData/shipper-consigner/shipper-consigner.module').then(m => m.ShipperConsignerModule) },

      // 运力管理
      { path: 'shipper/carryManager/driver/driver.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperDriverList' }, loadChildren: () => import('./views/shipper/carryManager/shipper-driver/shipper-driver.module').then(m => m.ShipperDriverModule) },
      { path: 'shipper/carryManager/team/team.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperTeamList' }, loadChildren: () => import('./views/shipper/carryManager/shipper-team/shipper-team.module').then(m => m.ShipperTeamModule) },
      { path: 'shipper/carryManager/truck/truck.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperTruckList' }, loadChildren: () => import('./views/shipper/carryManager/shipper-truck/shipper-truck.module').then(m => m.ShipperTruckModule) },
    

      { path: 'shipper/carryManager/broker/broker.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperBroker' }, loadChildren: () => import('./views/shipper/carryManager/shipper-broker/shipper-broker.module').then(m => m.ShipperBrokerModule) },
      { path: 'shipper/carryManager/agentor/agentor.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperAgentor' }, loadChildren: () => import('./views/shipper/carryManager/shipper-agentor/shipper-agentor.module').then(m => m.ShipperAgentorModule) },

      // 财务中心
      { path: 'shipper/financial/checkMoney/checkMoney.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperCheckMoney' }, loadChildren: () => import('./views/shipper/financial/shipper-check-money/shipper-check-money.module').then(m => m.ShipperCheckMoneyModule) },
      { path: 'shipper/financial/payMoney/payMoney.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperPayMoney' }, loadChildren: () => import('./views/shipper/financial/shipper-pay-money/shipper-pay-money.module').then(m => m.ShipperPayMoneyModule) },
      { path: 'shipper/financial/payMoneyDetail/payMoneyDetail.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperPayMoneyDetail' }, loadChildren: () => import('./views/shipper/financial/shipper-pay-money-detail/shipper-pay-money-detail.module').then(m => m.ShipperPayMoneyDetailModule) },
      { path: 'shipper/financial/statement/statement.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperStatement' }, loadChildren: () => import('./views/shipper/financial/shipper-statement/shipper-statement.module').then(m => m.ShipperStatementModule) },

      // 业务中心
      { path: 'shipper/business/order/order.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperOrderList' }, loadChildren: () => import('./views/shipper/business/shipper-order/shipper-order.module').then(m => m.ShipperOrderModule) },
      { path: 'shipper/business/publish/publish.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperPublishList' }, loadChildren: () => import('./views/shipper/business/shipper-publish/shipper-publish.module').then(m => m.ShipperPublishModule) },
      { path: 'shipper/business/waybill/waybill.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperWaybillList' }, loadChildren: () => import('./views/shipper/business/shipper-waybill/shipper-waybill.module').then(m => m.ShipperWaybillModule) },
      { path: 'shipper/business/bulkOrder/bulkOrder.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperBulkOrderList' }, loadChildren: () => import('./views/shipper/business/shipper-bulk-order/shipper-bulk-order.module').then(m => m.ShipperBulkOrderModule) },
      { path: 'shipper/business/updateWaybill/updateWaybill.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperUpdateWaybill' }, loadChildren: () => import('./views/shipper/business/shipper-update-waybill/shipper-update-waybill.module').then(m => m.ShipperUpdateWaybillModule) },//运单纠正功能

      // 系统管理
      { path: 'shipper/sys/user/user.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperUserList' }, loadChildren: () => import('./views/shipper/system/shipper-user-list/shipper-user-list.module').then(m => m.ShipperUserListModule) },
      { path: 'shipper/sys/role/role.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperRoleList' }, loadChildren: () => import('./views/shipper/system/shipper-role-list/shipper-role-list.module').then(m => m.ShipperRoleListModule) },
      { path: 'shipper/sys/appShipper/appShipper.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperAppShipper' }, loadChildren: () => import('./views/shipper/system/shipper-app-shipper/shipper-app-shipper.module').then(m => m.ShipperAppShipperModule) },
      { path: 'shipper/sys/shipperDamage/shipperDamage.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperDamage' }, loadChildren: () => import('./views/shipper/system/shipper-damage/shipper-damage.module').then(m => m.ShipperDamageMoule) },
      { path: 'shipper/sys/appDriver/appDriver.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperAppDriver' }, loadChildren: () => import('./views/shipper/system/shipper-app-driver/shipper-app-driver.module').then(m => m.ShipperAppDriverModule) },
      


      //发票管理
      { path: 'shipper/invoice/shipperApplyInvoice/shipperInvoice.html', resolve: { btnAuths: BtnAuthService }, data: { animation: 'shipperInvoice' }, loadChildren: () => import('./views/shipper/invoice/shipper-invoice/shipper-invoice.module').then(m => m.ShipperInvoiceModule) },
    ]
  },
  { path: 'login', data: { animation: 'shipOrder' }, loadChildren: () => import('./../page/main/login/login.module').then(m => m.LoginModule) },
  { path: 'editPasswrd', data: { animation: 'editPasswrd' }, loadChildren: () => import('./../page/main/edit-pass/edit-pass.module').then(m => m.EditPassModule) }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class RoutingModule { }
