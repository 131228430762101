import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { ComponentsModule } from './page/components.module';
import { AppComponent } from './app.component';

import { LocalStorage } from './utils/LocalStorage';
import { SessionStorage } from './utils/SessionStorage';
import { CusRouteReuseStrategy } from './utils/CusRouteReuseStrategy';
import { ReuseStrategyTab } from './utils/ReuseStrategyTab';
import { DefaultInterceptor } from './utils/DefaultInterceptor';
import { HttpHelpUtils } from './utils/HttpHelpUtils';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ComponentsModule,
    BrowserAnimationsModule
  ],
  providers: [
    LocalStorage,
    SessionStorage,
    HttpHelpUtils,
    ReuseStrategyTab,
    {
      provide: RouteReuseStrategy,
      useClass: CusRouteReuseStrategy,
      deps: [ReuseStrategyTab]
    }, 
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
