import { Injectable } from '@angular/core';
import {
    CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot,
    CanActivateChild, CanLoad, CanDeactivate
} from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { LoginComponent } from '../page/main/login/login.component';
import { SessionStorage } from './SessionStorage';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CusRouteReuseStrategy } from '../utils/CusRouteReuseStrategy';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad, CanDeactivate<any> {
    private sessionStorage: SessionStorage;
    constructor(
        private router: Router, 
        sessionStorage: SessionStorage) {
        this.sessionStorage = sessionStorage;
    }
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // jwt 验证
        const jwt = this.sessionStorage.getItem(environment.tokenKey);
        if (!jwt) {
            CusRouteReuseStrategy.handlers = {};
            this.router.navigateByUrl('login');
        }
        return true
    }
    async canActivateChild(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        return true;
    }
    canDeactivate(
        component: LoginComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        // return confirm('确定要离开此页面吗？');
        CusRouteReuseStrategy.handlers = {};
        return true;
    }
    canLoad(route: Route) {
        return true;
    }
}
