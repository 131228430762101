import { Component, OnInit, HostListener } from '@angular/core';
import { LocalStorage } from './utils/LocalStorage';
import { PrimeNGConfig } from 'primeng/api';
import { HttpHelpUtils } from './utils/HttpHelpUtils';
import { slideInAnimation } from './utils/Animations';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  template: `
    <p-blockUI [blocked]="httpHelp.loadingBlock" [baseZIndex]="100000">
      <div >
          <div>
              <img [src]="httpHelp.loadingGif" style="width: 160px;height:160px;" />
          </div>
          <div style="text-align: center;">
              <span style="color: white; font-size: large;">
                  {{httpHelp.loadingText}}
              </span>
          </div>
      </div>
    </p-blockUI>
    <div [@routeAnimations]="prepareRoute(outlet)" style="background-color:var(--surface-a)">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>`,
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements OnInit {
  title = '网络货运平台';

  constructor(
    private localStorage: LocalStorage,
    private config: PrimeNGConfig,
    public httpHelp: HttpHelpUtils
  ) {
    this.config.ripple = true;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.['animation'];
  }

  ngOnInit() {
    this.config.setTranslation({
      dayNames:	["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      dayNamesShort:	["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      dayNamesMin:	["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      monthNames:	["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"],
      monthNamesShort:	["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"],
      today: '今天',
      weekHeader: 'Wk',
      weak: '弱',
      medium: '中',
      strong: '强',
      passwordPrompt: '请输入密码',
      emptyFilterMessage: '无相关数据',
      emptyMessage: '暂无相关数据'
    });

    // 初始化主题以及字体大小
    document.documentElement.style.fontSize =  this.localStorage.get('theme-size') || '14px';
    const linkElement = document.getElementById('theme-link');
    const type = this.localStorage.get('theme-type') || 'light';
    if (type == 'light') {
        this.httpHelp.loadingGif = 'assets/file/image/loading-w.gif';
    }
    if (type == 'dark') {
      linkElement.setAttribute('href', linkElement.getAttribute('href').replace("assets/theme/res-theme/bootstrap4-light-purple.css",
      'assets/theme/res-theme/bootstrap4-dark-purple.css'));
      this.httpHelp.loadingGif = 'assets/file/image/loading-b.gif';
    } else if (type === 'light-blue') {
        linkElement.setAttribute('href', linkElement.getAttribute('href').replace("assets/theme/res-theme/bootstrap4-light-purple.css",
            'assets/theme/res-theme/bootstrap4-light-blue.css'));
        this.httpHelp.loadingGif = 'assets/file/image/loading-w.gif';
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  windowBeforeUnload(event: BeforeUnloadEvent) {
    //测试注释，生产放开
    //this.localStorage.remove(environment.tokenKey);
  }

}

